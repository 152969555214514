.avatar {
  .ant-avatar {
    background-color: #002677;

    img {
      height: 49px;
      width: 49px;
    }
  }
}

.avatar-bg {
  .ant-avatar {
    background-color: #002677;
  }
}

.avatar70 {
  .ant-avatar {
    background-color: #002677;
    .ant-avatar-string{
      font-size: 20px;
    }
    img {
      height: 70px;
      width: 70px;
    }
  }
}
