.profile {
  margin: 0 -24px;
  font-family: Noir Pro;

  .blue-div {
    min-height: 207px;
    padding: 32px 30px;
    padding-top: 20px;
    font-size: 14px;
    font-weight: 400;
    background-color: var(--primary);
    color: white;

    .details-div {
      width: 100%;
      @media only screen and (min-width: 800px) {
        width: 70%;
      }
    }

    .overview-underline {
      border-bottom: 1px solid #ffffff;
      padding-bottom: 3px;
      font-weight: 500;
    }
  }

  .image-div {
    display: flex;

    .profile-image2 {
      height: 70px;
      width: 70px;
      border-radius: 50%;
    }

    .upload-button {
      margin-left: 28px;
      margin-right: 20px;
    }

    @media only screen and (max-width: 1220px) {
      display: unset;
      .profile-image2 {
        height: 70px;
        width: 70px;
        border-radius: 50%;
      }

      .upload-button {
        margin-left: unset;
        margin-right: unset;
      }
    }
  }

  .update-div {
    font-family: Noir Pro !important;
    padding: 0 25px;
    position: absolute;
    top: -52px;
    width: 100%;
    .update-card {
      max-width: 784px;
      width: 100%;
      background-color: white;
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
      border-radius: 10px;

      .card-header {
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        color: #262626;
        background-color: white;
        font-size: 16px;
        font-weight: 400;
        height: 52px;
        border-bottom: 1px solid #e8e8e8;
      }

      .card-content {
        padding: 24px 30px;

      }
    }

    @media only screen and (max-width: 1115px) {
      .reset-password-div {
        margin-bottom: 75px;
      }
    }
  }
}
