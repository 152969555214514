@import "./src/common/styles/variables";

.Header {
  margin: -24px -24px 0 -24px;
  box-shadow: 0 -2px 15px 0 rgba(0, 0, 0, 0.2);
  height: 70px;
  padding: 21px 0px 20px 30px;
  font-size: 24px;
  font-weight: 600;
  color: #4a4a4a;
  background-color: white;
}