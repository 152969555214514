.loading {
  position: fixed;
  z-index: 999;
  height: 100%;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #ffffff85;
  .spin-div {
    position: relative;
    margin-left: 270px;
    height: 100%;
    .spin {
      position: absolute;
      top: 50%;
      left: 50%;
      //@media only screen and (min-width: 1080px) {
      //  left: 61%;
      //}
    }
  }
  .no-left {
    left: 50% !important;
  }
}
