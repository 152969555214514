.custom-modal {
  .modal-dialog {
    max-width: 650px;
    width: 100%;
    .modal-content {
      border: 1px solid #e0e0e0;
      border-radius: 8px;
      .md-header {
        border-radius: 5px 5px 0 0;
        padding: 21px 0 15px 30px;
        font-weight: 500;
        font-size: 16px;
        color: #545454;
        background-color: #fafafa;
        border-bottom: 1px solid #bdbdbd;
      }

      .md-content {
        padding: 10px 30px 30px 30px;
      }
    }

    .md-footer {
      border-radius: 0 0 5px 5px;
      padding: 15px 30px 17px 0;
      border-top: 1px solid #bdbdbd;
      background-color: #fafafa;
      .submit-btn {
        padding: 10px 54px;
        color: #ffffff;

        &:focus {
          box-shadow: none;
        }
      }

      .cancel-btn {
        height: 36px;
        border-radius: 6px;
        border-color: #d9d9d9;
        background-color: #ffffff;
        padding: 5px 29px;
        font-size: 14px;
        color: #545454;
        margin-right: 20px;

        &.btn:focus {
          box-shadow: unset;
        }
      }
    }
  }
}
//    .workflow-box {
//      cursor: pointer;
//    }
//    .workflow-box.in-active {
//      border: 1px solid white;
//    }
//    .workflow-box.active {
//      border: 1px solid #2eb85c;
//    }
//  }
//  .input-group {
//    &.price {
//      margin-top: 6px;
//      .form-control {
//        border-radius: 4px !important;
//        margin-right: 3px;
//      }
//      .form-control {
//        margin-left: 3px !important;
//      }
//    }
//  }
//  .location-model-content {
//    padding-right: 91px !important;
//    .form-control {
//      color: black;
//    }
//    textarea.form-control {
//      height: 58px;
//    }
//    input[type="text"] {
//      width: 194px;
//    }
//    .google-link {
//      color: #2d1fec;
//      font-size: 15px;
//    }
//    .form-control::-webkit-input-placeholder {
//      color: black;
//    }
//  }
//}
//
//@media (min-width: 768px) {
//  .custom-modal {
//    .modal-dialog {
//      .workflow-box.col-md-6 {
//        flex: 0 0 50%;
//        max-width: 49%;
//      }
//    }
//  }
//}
//
//.width-1000 {
//  @media (min-width: 768px) {
//    .modal-dialog {
//      max-width: 1050px;
//    }
//  }
//}
//
//.width-full {
//  .modal-dialog {
//    max-width: 1050px;
//
//    .modal-content {
//      height: 825px !important;
//    }
//  }
//}
//
//.loadingModal {
//  position: absolute !important;
//
//  .spin {
//    left: 50% !important;
//  }
//}
