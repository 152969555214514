.checkBox {
  display: flex;
  align-items: center;

  .label-div {
    font-family: 'Noir Pro';
    //margin-top: 2px;
    margin-left: 10px;
    color: #4A4A4A;
    font-size: 14px;
    line-height: 18px;
  }

  input[type="checkbox"] {
    //margin-top: 5px;
    border-color: #D8DCDF;
    border-radius: 3px;
    //width: 18px;
    //height: 18px;
  }

  .ant-checkbox-inner {
    width: 16px;
    height: 16px;
    border-color: #D8DCDF;
  }
}


.ant-checkbox .ant-checkbox-inner {
  background-color: #ffffff;
  border-color: #D8DCDF !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--secondary);
  border-color: #D8DCDF !important;
}


.ant-checkbox:hover {
  background-color: #F1B353;
  border-color: #D8DCDF !important;
}
