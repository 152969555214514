.set-password {
  .form-div {
    padding: 166px 100px 0 100px;
  }

  .set-password-title {
    font-family: 'Noir Pro';
    font-size: 20px;
    color: #262626;
    font-weight: 600;
    margin-bottom: 8px;
  }

  .set-password-description {
    text-align: left;
    font-size: 14px;
    //font-family: 'NoirPro';
    color: #757575;
    font-weight: 500;
  }

  .sign-in-input {
    height: 36px;
    width: 360px;
  }

}