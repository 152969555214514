.location-filter {
  font-weight: 400;
  font-size: 14px;
}
.statusIconsmain{
  display: flex;
  .statusIcons{
    width:35px;
    height:20px;
    svg{
      width:100%;
      height: 23px;
      fill: #1ab2a9;
    }
  }
}