.custom-table {
  .ant-table-wrapper {
    border-radius: 10px;
    overflow: hidden;
    perspective: 1px;

    table {
      border: 1px solid #e8e8e8;
      border-radius: 10px !important;

    }

    .ant-table-content {
      overflow: auto;
    }

    //.ant-table-thead{
    //  border-radius: 10px 0 0 10px !important;
    //}
    .ant-table-cell:before {
      height: 0 !important;
    }

    //.ant-table-cell {
    //  padding: 7px 16px;
    //}
    .ant-table-column-title {
      color: var(--lightPrimary) !important;
      font-weight: 600;
      //text-align: center;
    }

    .ant-pagination-prev,
    .ant-pagination-next {
      a {
        color: var(--lightSecondary) !important;
        font-weight: 500;
      }
    }
    .ant-pagination-item:focus-visible, .ant-pagination-item:hover{
      border: 1px solid var(--lightPrimary);
    }
    .ant-pagination-options {
      display: none;
    }

    .ant-table-cell {
      font-weight: 400;
      color: #545454;
    }

    .ant-pagination-item-active {
      border: 1px solid var(--lightSecondary);
      border-radius: 4px;
    }

    .ant-pagination-item {
      border-radius: 4px;
    }
  }

  .table-style {
    font-family: "Noir Pro";
    font-weight: 300;
    color: #545454;
  }

  .table-header-row {
    font-family: "Noir Pro";
    font-weight: 500;
    color: #545454;
  }

  div::-webkit-scrollbar {
    height: 13px !important;
    width: 7px;
  }

  div::-webkit-scrollbar-track {
    webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  div::-webkit-scrollbar-thumb {
    background-color: var(--secondary);
    border-radius: 6.5px;
    webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
}

.table-50px {
  .ant-table-wrapper {
    .ant-table-cell {
      color: #545454;
      font-weight: 400;
      padding: 7px 16px;
    }
  }
}
