.search-bar {
  width: 100%;
  display: flex;
  .button-div {
    position: relative;
    max-width: 338px;
    width: 100%;
    input{
      border-radius: 4px 0 0 4px;
      padding-right: 45px;
      height: 36px;
    }
    button {
      background-color: var(--lightSecondary) !important;
      color: white;
      border-radius: 0 4px 4px 0;
      height: 36px;
      width: 42px;
      position: absolute;
      top: 0;
      //border: 1px solid #0134a0;
      right: 0px;
      //span{
      //  padding: 0 22px;
      //}
    }
  }
}
