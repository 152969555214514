body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --primary: #2f2e2e;
  --lightPrimary: #2f2e2ec7;
  --primaryHover: #242323;
  --secondary: #1f366c;
  --secondaryHover: #f6d094;
  --lightSecondary: #0e4c8c;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Noir Pro";
  src: url("../src/assets/fonts/NoirPro-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../src/assets/fonts/NoirPro-Light.woff") format("woff"),
    url("../src/assets/fonts/NoirPro-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Noir Pro";
  src: url("../src/assets/fonts/NoirPro-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../src/assets/fonts/NoirPro-Regular.woff") format("woff"),
    url("../src/assets/fonts/NoirPro-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Noir Pro";
  src: url("../src/assets/fonts/NoirPro-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../src/assets/fonts/NoirPro-Medium.woff") format("woff"),
    url("../src/assets/fonts/NoirPro-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Noir Pro";
  src: url("../src/assets/fonts/NoirPro-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("../src/assets/fonts/NoirPro-SemiBold.woff") format("woff"),
    url("../src/assets/fonts/NoirPro-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Noir Pro";
  src: url("../src/assets/fonts/NoirPro-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../src/assets/fonts/NoirPro-Bold.woff") format("woff"),
    url("../src/assets/fonts/NoirPro-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Noir Pro";
  src: url("../src/assets/fonts/NoirPro-Heavy.eot?#iefix")
      format("embedded-opentype"),
    url("../src/assets/fonts/NoirPro-Heavy.woff") format("woff"),
    url("../src/assets/fonts/NoirPro-Heavy.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
}

body {
  font-family: "Noir Pro" !important;
}

.font-noir-pro {
  font-family: "Noir Pro";
}

.color-blue {
  color: #0134a0 !important;
}

.primary-color {
  color: var(--primary) !important;
}
.ant-picker {
  height: 35px;
}
.custom-select-input
  .ant-select
  .ant-select-selector
  .ant-select-selection-item {
  color: #545454;
  font-weight: 400;
  padding-top: 4px;
  width: 100px !important;
}

.bg-primary-color {
  background-color: var(--primary) !important;
}

.primary-color-hover {
  color: var(--primaryHover) !important;
}

.bg-primary-hover-color {
  background-color: var(--primaryHover) !important;
}

.secondary-color {
  color: var(--secondary) !important;
}

.secondary-color-hover {
  color: var(--secondaryHover) !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-17 {
  font-size: 17px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.mb-6 {
  margin-bottom: 6px;
}

.mt-6 {
  margin-top: 6px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-11 {
  margin-top: 11px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-13 {
  margin-top: 13px;
}

.mt-14 {
  margin-top: 14px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-18 {
  margin-top: 18px;
}

.mt-19 {
  margin-top: 19px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-22 {
  margin-top: 22px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-26 {
  margin-top: 26px;
}

.mt-33 {
  margin-top: 33px;
}

.mt-35 {
  margin-top: 35px;
}

.ml-6 {
  margin-left: 6px;
}

.ml-8 {
  margin-left: 8px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-11 {
  margin-left: 11px;
}

.ml-12 {
  margin-left: 12px;
}

.ml-14 {
  margin-left: 14px;
}

.ml-16 {
  margin-left: 16px;
}

.ml-18 {
  margin-left: 18px;
}

.ml-19 {
  margin-left: 19px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-22 {
  margin-left: 22px;
}

.ml-24 {
  margin-left: 24px;
}

.ml-26 {
  margin-left: 26px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-33 {
  margin-left: 33px;
}

.ml-35 {
  margin-left: 35px;
}

.mr-6 {
  margin-right: 6px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-12 {
  margin-right: 12px;
}

.mr-14 {
  margin-right: 14px;
}

.mr-16 {
  margin-right: 16px;
}

.mr-18 {
  margin-right: 18px;
}

.mr-19 {
  margin-right: 19px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-22 {
  margin-right: 22px;
}

.mr-24 {
  margin-right: 24px;
}

.mr-26 {
  margin-right: 26px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-33 {
  margin-right: 33px;
}

.mr-35 {
  margin-right: 35px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-14 {
  margin-bottom: 14px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-18 {
  margin-bottom: 18px;
}

.mb-19 {
  margin-bottom: 19px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-22 {
  margin-bottom: 22px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-33 {
  margin-bottom: 33px;
}

.mb-35 {
  margin-bottom: 35px;
}

.cursor-pointer {
  cursor: pointer;
}

.app {
  height: 100vh;
  display: flex;
  position: relative;
}

.ctm-col-lg-2 {
  flex: 0 0 20% !important;
  max-width: 20.666667% !important;
}

main {
  padding: 24px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.card {
  background-color: white;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px !important;
  padding: 25px 30px 30px 30px;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  color: var(--primary) !important;
  background: #fafafa;
  font-weight: 500;
}

.ant-dropdown-menu-item {
  min-width: 106px !important;
}

.box-padding-main {
  padding: 30px 6px 30px 6px;
}

.box-margin-main {
  margin: 30px 6px 30px 6px;
}
