$color_blue: #0666EB;
$color_lightgray: #8B959E;
$color_gray50: #4F5566;
$color_darkgray: #848FA2;
$color_lightShadeOfGray: #DDDDDD;
$color_gray: #848FA2;
$color_lightShadeGray: #DADADA;
$color_lighterGray: #EEEEEE;
$color_lightestGray :#F4F4F4;
$color_lightWeightGray: #E5E5E5;
$color_darkBlue: #0A2239;
$color_blue70: #055BD2;
$lightblue: #4169E1;
$color_white: #fff;
$color_pink: #eb008d;
$color_hover_gray: #262626;
$color_black: #000000;
$color_solidBlack: #343434;
$color_lightBlack: #333333;
$color_Silver: #C4C4C4;
$color_red: #ED2939;
$color_btnBoxShadow: rgba(6, 102, 235, 0.25);
$color_lightBlue: #F2F5F8;
$color_lightWeightBlue: #EFF6FF;
$color_yellow: #FFAB2E;
$color_lightYellow:#FFDB1C;
$color_background_gray: #F5F5F5;
$color_link: #0E8DEA;
$color_darkblue: #002060;
$color_darkGreen: #005677;
$color_dragonRed: #ED2939;
$color_solidGray: #DADADA;
$color_solidGray2: #E6E6E8;
$bgcolor_gray: #313131;

$fs_11: 11px;
$fs_12: 12px;
$fs_13: 13px;
$fs_14: 14px;
$fs_15: 15px;
$fs_16: 16px;
$fs_17: 17px;
$fs_18: 18px;
$fs_21: 21px;
$fs_22: 22px;
$fs_24: 24px;
$fs_24: 24px;
$fs_25: 25px;
$fs_28: 28px;
$fs_32: 32px;
$fs_34: 34px;
$fs_42: 42px;
$fs_52: 52px;

$fw_200: 200;
$fw_500: 500;
$fw_600: 600;
$fw_900: 900;
$fw_bold: bold;
$fw_normal: normal;

$m_5: 5px;
$m_8: 8px;
$m_10: 10px;
$m_12: 12px;
$m_15: 15px;
$m_16: 16px;
$m_17: 17px;
$m_18: 18px;
$m_20: 20px;
$m_22: 22px;
$m_23: 23px;
$m_25: 25px;
$m_27: 27px;
$m_30: 30px;
$m_34: 34px;
$m_35: 35px;
$m_38: 38px;
$m_40: 40px;
$m_45: 45px;
$m_50: 50px;
$m_55: 55px;
$m_60: 60px;
$m_70: 70px;
$m_75: 75px;
$m_80: 80px;
$m_85: 85px;
$m_90: 90px;
$m_95: 95px;
$m_100: 100px;
$m_120: 120px;
$m_130: 130px;
$m_140: 140px;
$m_155: 155px;
$m_200: 200px;
$m_250: 250px;
