.sign-in-box {
  .logo-div {
    padding: 51px 0px;
    width: 355px;
    height: 100%;
  }

  .login-card {
    //box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 5px 5px rgba(0, 0, 0 , 0.04);
    background-color: white;
    max-width: 1113px;
    max-height: 500px;
    overflow: hidden;
    perspective: 1px;
    border-radius: 8px;

    @media only screen and (max-width: 990px) {
      //width: 557px;
      //height: 556px;
      .image-div-login {
        opacity: 0.3;
        max-width: 100%;
        max-height: 100%;
        position: absolute;
      }
      .form-div {
        max-width: 100%;
        max-height: 100%;
        position: absolute;
      }
    }

    .image-div-login {
      max-height: 556px;
      max-width: 557px;

      img {
        height: 556px;
        width: 557px;
      }
    }

    .form-div {
      //.singin-title {
      //  font-family: 'NoirPro';
      //  font-size: 20px;
      //  color: #262626;
      //  font-weight: 600;
      //  margin-bottom: 24px;
      //}
      max-height: 556px;
      max-width: 556px;

      .sign-in-input {
        height: 36px;
        width: 363px;
      }
    }
  }
}
