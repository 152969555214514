.export-button {
  width: fit-content;

  button {
    width: 139px;
    background-color: var(--secondary) !important;
    border-radius: 4px;
    color: var(--primaryHover) !important;
    font-size: 14px;
    font-weight: 500;
    font-family: "Noir Pro";
    height: 36px;
    img {
      position: absolute;
      left: 22px;
      top: 8px;
    }
    // img {
    //   filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(345deg)
    //     brightness(0%) contrast(120%);
    // }

    span {
      padding: 2px 0px;
      padding-left: 30px;
      color: #ffffff;
    }
  }
  button:focus,
  button:hover {
    background-color: #2956a0;
    color: white;
  }
}
