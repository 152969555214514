.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 68px;
  padding: 0 29px;
  background-color: #FFFFFF;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.04);
  //box-shadow: 0 8px 16px 0 #000000;
  display: table;

  .footer-div {
    display: table-cell;
    vertical-align: middle;
    font-size: 14px;
    font-weight: 400;
    color: #757575;
  }
}