@import "./src/common/styles/variables";

.ChartCard {
  padding: 17px 23px;
  position: relative;
  height: 122px;
  border-radius: 10px;
  max-width: 377px;
  width: 100%;
  background-color: white;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.04);
  //box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
  perspective: 1px;

  .digit-div {
    font-weight: 400;
    font-size: 24px;
    color: #212121;
  }

  .label-div {
    line-height: 11px;
    font-size: 14px;
    font-weight: 400;
    color: #8c8c8c;
  }

  .chart-div {
    margin-left: -23px;
    width: 100%;
    position: absolute;
    bottom: 0;
  }
}
