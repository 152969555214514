.custom-input {
  width: 100%;


  .label {
    color: #545454;
    line-height: 20px;
    margin-bottom: 11px;
  }

  .ant-input {
    color: #545454;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    //font-family: "NoirPro";
    font-weight: 400;
    //padding-top: 4px !important;
  }

  .ant-input-affix-wrapper > input.ant-input {
    padding-left: 4px;
  }

  .forgot-input {
    .ant-input-prefix {
      img {
        margin: 0 8px 0 12px;
      }
    }

    input {
      margin: 0px !important;
      padding-left: 0 !important;
      height: 100% !important;
    }
  }

  .ant-input-affix-wrapper {
    border-color: #d8dcdf;
    border-radius: 4px;
    padding: 0 11px 0 0;
    height: 31px;

    .ant-input-prefix {
      //margin-left: 15px;
      //margin-right: 16px;

      img {
        width: 11px;
        height: 12px;
        //margin-right: 12px;
      }
    }
  }

  .ant-form-item {
    margin-bottom: unset;

    .ant-form-item-control-input-content .ant-picker {
      height: 36px;
    }
  }
}
