.details {
  margin: 0 -24px;
  //font-family: NoirPro;
  .ant-card-extra {
    width: 70%;
  }
  .need-button {
    border: 2px solid;
    border-radius: 4px;
    padding: 3px 13px 9px 14px;
    height: 30px;
    width: 130px;
  }
  .launch-button {
    margin-left: 15px;
    padding: 3px 13px 9px 14px;
    height: 30px;
    background-color: #ad9863;
    border: none;
    width: 200px;
    border-radius: 3px;
  }
  .blue-div-image {
    background-image: url("../../assets/images/map.png");

    .blue-div-on-image {
      background-color: rgba(8, 67, 128,.7) !important;
      color: white;
      min-height: 207px;
      padding: 32px 30px;
      padding-top: 20px;
      font-size: 14px;
      font-weight: 400;

      .details-div {
        width: 100%;
        @media only screen and (min-width: 800px) {
          width: 70%;
        }
      }

      .overview-underline {
        border-bottom: 1px solid #ffffff;
        padding-bottom: 3px;
      }
    }
  }

  .update-div {
    //font-family: NoirPro !important;
    padding: 0 25px;
    position: absolute;
    top: -52px;
    left: 15px;
    width: 100%;

    .update-card {
      max-width: 784px;
      width: 100%;
      background-color: white;
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
      border-radius: 10px;

      .card-header {
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        color: #262626;
        background-color: white;
        font-size: 16px;
        font-weight: 500;
        height: 52px;
        border-bottom: 1px solid #e8e8e8;
      }

      .card-content {
        padding: 20px 30px;

        .password-teamviewer {
          padding: 21.5px
        }
      }
    }

    .style-font {
      font-family: "Noir Pro";
      font-size: 14px;
      line-height: 18px;
      color: #545454;
    }
  }

  .amcharts-main-div {
    .amcharts-chart-div {
      a {
        display: none !important;
      }
    }
  }

  .overview {
    display: flex;
    flex-wrap: wrap;
    @media only screen and (max-width: 1115px) {
      //width: 70%;
      display: unset;
      .div-1 {
        //width: 76.5%;
        .graph-div {
          margin-right: unset !important;
        }

        flex: unset !important;
        max-width: unset !important;
        width: 100% !important;
      }
      .div-2 {
        margin-top: 20px !important;
        flex: unset !important;
        max-width: unset !important;
        width: 100% !important;
      }
    }
    @media only screen and (max-width: 1450px) and (min-width: 1114px) {
      .div-1 {
        //width: 76.5%;
        .graph-div {
          margin-right: 30px;
        }

        flex: 0 0 65% !important;
        max-width: 65% !important;
      }
      .div-2 {
        //width: 23.5% !important;
        flex: 0 0 35% !important;
        max-width: 35% !important;
      }
    }

    .div-1 {
      //width: 76.5%;
      .graph-div {
        margin-right: 30px;
      }

      flex: 0 0 65% !important;
      max-width: 65% !important;
    }

    .div-2 {
      //width: 23.5%;
      flex: 0 0 35%;
      max-width: 35%
    }
  }
}
