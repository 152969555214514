.customButton-blue {
  width: fit-content;

  button {
    border: 0 !important;
    background-color: var(--secondary) !important;;
    font-size: 14px;
    font-family: "Noir Pro";
    border-radius: 4px;
    height: 36px;

    span {
      padding: 0 22px;
      color: #ffffff;
    }
  }

  button:focus, button:hover {
    background-color: var(--secondaryHover) !important;
    color: var(--primary) !important;;
  }
}

// 064B8D