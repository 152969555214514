.account{
  padding: 30px 6px 30px 6px;
  .account-filter {
    font-weight: 400;
    font-size: 14px;
  }
}

.add-user-style{
  font-weight: 400;
  font-size: 14px;
}