.adminDBBoxes {
  @media screen and (min-width: 1025px) {
    .ant-row {
      justify-content: space-between;
    }
  }
}

.dashboard-details {
  margin: 72px -24px;
  //font-family: NoirPro;
  .ant-card-extra {
    width: 70%;
  }

  .blue-div {
    min-height: 207px;
    padding: 32px 30px;
    padding-top: 20px;
    font-size: 14px;
    font-weight: 400;
    background-color: #002b85;
    color: white;

    .details-div {
      width: 100%;
      @media only screen and (min-width: 800px) {
        width: 70%;
      }
    }

    .overview-underline {
      border-bottom: 1px solid #ffffff;
      padding-bottom: 3px;
    }
  }

  .update-div {
    //font-family: NoirPro !important;
    padding: 0 25px;
    position: absolute;
    top: -52px;
    left: 15px;
    width: 100%;

    .update-card {
      max-width: 784px;
      width: 100%;
      background-color: white;
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
      border-radius: 10px;

      .card-header {
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        color: #262626;
        background-color: white;
        font-size: 16px;
        font-weight: 600;
        height: 52px;
        border-bottom: 1px solid #e8e8e8;
      }

      .card-content {
        padding: 20px 30px;
      }
    }
  }

  .amcharts-main-div {
    .amcharts-chart-div {
      a {
        display: none !important;
      }
    }
  }

  .ant-card {
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.04);
  }

  .overview {
    display: flex;
    flex-wrap: wrap;
    @media only screen and (max-width: 1115px) {
      //width: 70%;
      display: unset;
      .div-1 {
        //width: 76.5%;
        .graph-div {
          margin-right: unset !important;
        }

        flex: unset !important;
        max-width: unset !important;
        width: 100% !important;
      }
      .div-2 {
        margin-top: 20px !important;
        flex: unset !important;
        max-width: unset !important;
        width: 100% !important;
      }
    }
    @media only screen and (max-width: 1450px) and (min-width: 1114px) {
      .div-1 {
        //width: 76.5%;
        .graph-div {
          margin-right: 30px;
        }

        flex: 0 0 65% !important;
        max-width: 65% !important;
      }
      .div-2 {
        //width: 23.5% !important;
        flex: 0 0 35% !important;
        max-width: 35% !important;
      }
    }

    .div-1 {
      //width: 76.5%;
      .graph-div {
        margin-right: 30px;
      }

      flex: 0 0 65%;
      max-width: 65%;
    }

    .div-2 {
      //width: 23.5%;
      flex: 0 0 35%;
      max-width: 35%;
    }
  }
}

.five-entries {
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.04);
  color: #262626;
  margin-top: -50px;
  @media only screen and (max-width: 1115px) {
    margin-top: 30px;
  }
  margin-bottom: 100px;
  width: 100%;
}
.dashboard-modal {
  .model-profile {
    background-color: #0e4c8c;
    width: 72px;
    height: 72px;
    border-radius: 50px;
    .model-profile-title {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      color: #e8e8e8;
    }
  }
  .complete-title{
    color:#242323;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
  }
  .complete-title-two{
    color: #5E5E5E;
  }
}
