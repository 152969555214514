@import "./src/common/styles/variables";


.ant-card-head-title {
  color: #262626;
  font-size: 16px;
  font-weight: 500;
}

.AuditBox {
  //max-width: 376px;
  min-width: 295px;
  overflow: auto;
  @media only screen and (max-width: 1114px) {
    max-width: unset;
  }
  height: 696px;
  //height: 646px;
  border-radius: 10px;

  .ant-card-head {
    min-height: 68px;
    padding-left: 25px;

    .ant-card-head-wrapper {
      height: 100%;
    }
  }

  .ant-card-body {
    padding-top: 30px;
    padding-left: 25px;
  }

  .ant-steps-item-container {
    min-height: 93px;

    .ant-steps-item-icon {
      //padding-top: 4px;
      margin-top: 10px;
    }

    .ant-steps-item-content {
      position: relative;
      left: 20px;

      .ant-steps-item-title {
        top: 1px;
        color: #212121;
        font-size: 17px;

        .machine-status {
          padding-top: 14px;

          h5 {
            color: #212121;
            font-size: 17px;
            font-weight: 500;
          }
        }

        .store-address {
          position: relative;
          top: -13px;
          font-size: 13px;
          color: #545454;
        }
      }

      .ant-steps-item-description {
        position: absolute;
        color: #8c8c8c;
        top: -2px;
      }
    }

    .ant-steps-item-tail:after {
      //width: 1px;
      height: 110%;
      background-color: unset;
      border-left: 1px dashed #979797;
      margin-left: 4px;
      margin-top: 13px;
    }
  }

  .AuditLogBar {
    position: relative;
    top: 20px;
    max-height: 245px;
    overflow: auto;
    margin-bottom: 20px;
    padding: 20px;

    .ant-timeline-item-content {
      top: -20.2px;
      margin: 0 0 0 40px;

      p {
        color: #bfbdbd;
        font-size: 11px;
        margin-bottom: 0px;

        &:last-child {
          color: grey;
        }

        span {
          color: #333;
          font-weight: 500;
          display: inline-block;
          margin-right: 3px;
        }
      }

      h5 {
        margin-bottom: 0px;
        padding: 3px 0px;
        font-weight: 500;
        font-size: 15px;
      }
    }
  }
}
