.sign-in {
  .form-div {
    padding: 178px 94px 144px 99px;
  }

  .singin-title {
    font-family: 'Noir Pro';
    font-size: 20px;
    color: #262626;
    margin-bottom: 24px;
    line-height: 24px;
  }
   
  .sign-in-input {
    height: 36px;
    width: 363px;
  }

  .ant-input {
    font-family: 'Noir Pro';
    font-size: 14px;
    color: #9F9F9F;
    font-weight: 400;
    line-height: 18px;
    //height: 18px;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .forgot-password {
    font-family: 'Noir Pro';
    line-height: 18px;
    font-size: 14px;
    color: #26619B !important;
    height: 18px;
    width: 130px;
  }

  //.ant-checkbox-wrapper{
  //  width: 16px;
  //  height: 16px;
  //}

}