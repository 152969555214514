.customButton2 {
  width: fit-content;
  button {
    //background-color: #0134A0;
    color: #545454;
    border-color: #D9D9D9;
    font-size: 14px;
    font-family: "Noir Pro";
    border-radius: 6px;
    height: 36px;
    display: flex;
    img{
      position: absolute;
      left: 20px;
      top: 10px;
    }
    span{
      padding: 4px 0px;
      padding-left: 30px;
    }
  }
}