@import "~react-pro-sidebar/dist/scss/styles.scss";

$sidebar-bg-color: #1d1d1d !default;
$sidebar-color: #adadad !default;
$sidebar-width: 260px !important !default;
$sidebar-height: 100% !default;
$sidebar-collapsed-width: 80px !default;
//$highlight-color: #d8d8d8 !default;
//$submenu-bg-color: #2b2b2b !default;
//$submenu-bg-color-collapsed: #2b2b2b !default;
//$icon-bg-color: #2b2b2b !default;
//$icon-size: 35px !default;
//$breakpoint-xs: 480px !default;
//$breakpoint-sm: 576px !default;
//$breakpoint-md: 768px !default;
//$breakpoint-lg: 992px !default;
//$breakpoint-xl: 1200px !default;
//$sidebar-bg-color: #1d1d1d;
.pro-sidebar {
  // position: fixed;
  // left: 0;
  //width: 260px!important;
  // background-color: #0134A0;

  .pro-sidebar-header {
    border-bottom: 0 !important;
    height: 70px;
    background-color: #084380 !important;
    position: relative;

    .expand-div:hover {
      background-color: #084380 !important;
    }

    .expand-div {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 14px;
      left: 20px;

      .expandIcon {
        width: 17px;
        height: 15px;
        position: absolute;
        top: 14px;
        left: 10px;
      }
    }

    .logo {
      position: absolute;
      right: 48px;
      top: 6.63px;
      width: 150px;
      height: 56.82px;
      bottom: 20px;
    }
  }

  .pro-sidebar-content {
    background-color: #064b8d !important;
  }

  .pro-menu {
    padding-top: 5px;

    .pro-menu-item {
      color: #ffffff !important;
      font-size: 14px;
      font-weight: 500;
      .pro-icon-wrapper{
        background: none !important;
      }
      .pro-inner-item:hover > .pro-icon-wrapper {
        .pro-icon {
          animation: unset;
        }
      }

      .pro-inner-item > .pro-icon-wrapper {
        //padding: 8px 35px 8px 14px;
        .pro-icon {
          // background-color: #AE9963;
          //height: 49px;
          //width: 49px;
        }
      }
    }
  }

  .profile-image {
    height: 49px;
    min-width: 49px;
    max-width: 49px;
    border-radius: 50%;
  }

  .divider-div {
    width: auto;
    margin-top: -15px;

    .divider {
      border-top: 0 !important;
      height: 1px;
      width: 90%;
      margin: 20px 0;
      background-color: #6189B3;
    }
  }
  .pro-sidebar
    .pro-menu.shaped
    .pro-menu-item
    > .pro-inner-item
    > .pro-icon-wrapper {
    background-color: none !important;
  }
  .profile-div {
    margin-left: 12px;
    width: 122px;
    position: relative;

    .right-arrow {
      position: absolute;
      right: 0;
      top: 0px;
      width: 20px;
    }

    .label {
      font-size: 16px;
      font-weight: 400;
      //font-family: "NoirPro";
    }

    .sub-label {
      font-size: 12px;
      font-family: "Noir Pro";
    }
  }

  .pro-menu-item-active {
    background-color: #ae9963;

    .pro-icon-wrapper .pro-icon {
      // background-color: #AE9963 !important;

      // img,
      // svg {
      //   filter: invert(33%) sepia(115%) saturate(400%) hue-rotate(345deg)
      //     brightness(102%) contrast(62%);
      // }
    }

    .pro-item-content {
      // color: var(--secondaryHover) !important;
      color: #ffffff !important;
    }
  }
}

.pro-item-content {
  font-weight: 400;
}

.pro-item-content {
  font-weight: 400;
}

.pro-item-content {
  font-weight: 400;
}

.profile-tab-div {
  height: 70px;

  .pro-icon-wrapper .pro-icon {
    img,
    svg {
      filter: unset !important;
    }
  }

  .pro-inner-item {
    height: 70px;
  }
}
