.custom-select-input {
  width: 100%;

  .label {
    line-height: 20px;
    margin-bottom: 11px;
  }

  .ant-select {
    border-radius: 4px;
    width: 100%;

    .ant-select-selector {
      border-radius: 4px;
      height: 36px;

      .ant-select-selection-placeholder {
        color: #A7A7A7;
        padding-top: 4px;
      }

      .ant-select-selection-item {
        color: #545454;
        font-weight: 400;
        padding-top: 4px;
      }
    }
  }

  .ant-input-affix-wrapper {
    border-color: #D8DCDF;
    border-radius: 4px;

    .ant-input-prefix {
      img {
        width: 15px;
        margin-right: 12PX;
      }
    }
  }

  .ant-form-item {
    margin-bottom: unset;
  }
}